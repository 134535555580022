

















































































































































































import Vue from 'vue'
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination,
  BDropdown, BDropdownItem, BButton, BLink,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import { appSmsList } from '@/store/modules/app-sms-templates/app-sms-list'
import { permissionSubjects } from '@/libs/acl/constants'

let debounceTimeout: ReturnType<typeof setTimeout> = setTimeout(() => '', 1000)

export default Vue.extend({
  name: 'SmsTemplatesList',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BButton,
    BLink,

    vSelect,

    TableSpinner,
  },
  data() {
    return {
      currentRouteName: this.$router.currentRoute.name,
      permissionSubjects,
    }
  },
  computed: {
    tableColumns() {
      return appSmsList.getters.getTableColumns
    },
    perPage() {
      return appSmsList.getters.getPerPage
    },
    totalItems() {
      return appSmsList.getters.getTotalItems
    },
    currentPage: {
      get() {
        return appSmsList.getters.getCurrentPage
      },
      set(val: number) {
        appSmsList.mutations.SET_CURRENT_PAGE(val)
      },
    },
    perPageOptions() {
      return appSmsList.getters.getPerPageOptions
    },
    searchQuery: {
      get() {
        return appSmsList.getters.getSearchQuery
      },
      set(val: string) {
        appSmsList.mutations.SET_SEARCH_QUERY(val)
      },
    },
    sortBy() {
      return appSmsList.getters.getSortBy
    },
    isSortDirDesc() {
      return appSmsList.getters.getIsSortDirDesc
    },
    dataMeta() {
      return appSmsList.getters.getDataMeta
    },
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.updateTable()
    },
    currentPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'currentPage', val)
      }
    },
    perPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'perPage', val)
      }
    },
    searchQuery(val, prevVal) {
      if (val !== prevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(this, this.currentRouteName, 'searchQuery', val)
        }, 250)
      }
    },
  },
  mounted() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  created() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    updatePerPage(val: number) {
      appSmsList.mutations.SET_PER_PAGE(val)
    },
    async fetchSmsTemplatesList() {
      return appSmsList.actions.fetchSmsTemplatesList()
    },
    async deleteItem(id: string) {
      await appSmsList.actions.deleteSmsTemplate(id)
      this.updateTable()
    },
    async cloneTemplate(id: string) {
      await appSmsList.actions.cloneSmsTemplate(id)
      this.updateTable()
    },
    setFilterParams() {
      appSmsList.mutations.SET_FILTER_PARAMS()
    },
    updateTable() {
      (this.$refs.refSmsTemplatesListTable as BTable).refresh()
    },
  },
})
